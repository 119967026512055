<template>
	<div class="home flex columns a-center">
		<specialities />
	</div>
</template>



<script>
import specialities from "../components/Specialties";
export default {
	name: "Home",
	components: { specialities },
};
</script>
